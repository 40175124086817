<template>
	<div class="page_mar">
		<!-- 搜索栏 -->
		<div class="mod_mar">
			<label>名称</label>
			<el-input v-model="formSearch.name" placeholder="请输入名称" clearable size="small" class="input_mar"></el-input>

			<label>账号</label>
			<el-input v-model="formSearch.account" placeholder="请输入账号" clearable size="small" class="input_mar">
			</el-input>

			<label>手机号</label>
			<el-input v-model="formSearch.phone" placeholder="请输入手机号" clearable size="small" class="input_mar">
			</el-input>
			<label>状态</label>
			<el-select v-model="formSearch.status" placeholder="请选择" clearable size="small" class="input_mar">
				<el-option value="0" label="禁用"></el-option>
				<el-option value="1" label="启用"></el-option>
			</el-select>

			<!-- 搜索按钮 -->
			<el-button type="primary" size="small" @click="UpdateData()" class="btn_w">
				<i class="el-icon-search">查询</i>
			</el-button>
			<el-button type="primary" size="small" style="float: right" class="btn_w" @click="
          showAdd = true;
          title = '添加账号';
        ">
				添加账号
			</el-button>
		</div>
		<!-- 表格 -->
		<div class="mod_mar">
			<el-table style="width: 100%" :data="tableData" border>
				<el-table-column prop="id" label="序号" width="60px" align="center"></el-table-column>
				<el-table-column prop="name" label="名称"> </el-table-column>
				<el-table-column prop="is_trial_text" label="是否试用" align="center">
				</el-table-column>
				<el-table-column prop="expire_time" label="到期时间" min-width="160px" align="center"></el-table-column>
				<el-table-column prop="pname" label="账号" align="center" min-width="160px">
					<template v-slot="scope">
						<div>{{ scope.row.pname }}</div>
						<div>{{ scope.row.mobile }}</div>
						<div>{{ scope.row.email }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="status_text" label="状态" align="center">
					<template v-slot="scope">
						<div v-if="scope.row.status_text == '启用'" style="color: green">
							{{ scope.row.status_text }}
						</div>
						<div v-if="scope.row.status_text == '禁用'">
							{{ scope.row.status_text }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="notes" label="备注"></el-table-column>
				<el-table-column prop="create_time" label="创建日期" min-width="160px" align="center"></el-table-column>
				<el-table-column label="操作" align="center" min-width="200px">
					<template v-slot="scope">
						<el-button type="text" @click="hanleeditStatus(scope.row)">
							<span v-if="scope.row.status_text == '禁用'">启用</span>
							<span v-if="scope.row.status_text == '启用'">禁用</span>
						</el-button>
						<el-button type="text" @click="hanleeditPlat(scope.row)">
							编辑
						</el-button>
						<el-button type="text" @click="
                showResetPwd = true;
                form.plat_id = scope.row.id;
              ">
							重置密码
						</el-button>
						<el-button type="text" style="color: red" @click="hanleDetails(scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 分页 -->
		<div class="tenant-page">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
				:total="total"></el-pagination>
		</div>

		<!-- 弹窗 -->
		<div class="dailog">
			<el-dialog v-model="showAdd" :close-on-click-modal="false" :title="title" center destroy-on-close="true"
				:width="'40%'" @close="ResetForm()">
				<el-form :model="form" :rules="rules" ref="form" size="small" label-position="right"
					:label-width="'100px'">
					<el-form-item label="平台名称：" prop="name">
						<el-input v-model="form.name" autocomplete="off" placeholder="请输入平台名称" size="small"
							class="inp-w"></el-input>
					</el-form-item>
					<el-form-item label="到期时间：" prop="expire_time">
						<el-date-picker v-model="form.expire_time" type="datetime" placeholder="选择日期" class="inp-w"
							style="width: 100%" :disabledDate="disabledDate" value-format="YYYY-MM-DD HH:mm:ss">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="LOGO：" prop="avatar">
						<el-upload class="avatar-uploader" :data="UpdateImageData" :action="upLoadUrl"
							:show-file-list="false" :on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload" :headers="headers" name="image">
							<img v-if="form.avatar" :src="form.avatar" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<div class="el-upload__tip">LOGO仅支持 JPG/PNG 格式</div>
					</el-form-item>
					<el-form-item label="是否试用：">
						<el-switch v-model="form.is_trial" active-color="#13ce66" inactive-color="#d3d3d3"
							:active-value="1" :inactive-value="0">
						</el-switch>
					</el-form-item>
					<el-form-item label="账号：" prop="pname">
						<el-input v-model="form.pname" autocomplete="off" placeholder="请输入账号" size="small"
							class="inp-w"></el-input>
					</el-form-item>
					<el-form-item label="密码：" prop="password" v-if="title == '添加账号'">
						<el-input v-model="form.password" type="password" autocomplete="off" placeholder="请输入密码"
							size="small" class="inp-w"></el-input>
					</el-form-item>
					<el-form-item label="确认密码：" prop="con_password" v-if="title == '添加账号'">
						<el-input v-model="form.con_password" type="password" autocomplete="off" placeholder="再次确认密码"
							size="small" class="inp-w"></el-input>
					</el-form-item>
					<el-form-item label="手机号：" prop="mobile">
						<el-input v-model="form.mobile" autocomplete="off" placeholder="请输入手机号" size="small"
							class="inp-w"></el-input>
					</el-form-item>
					<el-form-item label="邮箱：" prop="email">
						<el-input v-model="form.email" autocomplete="off" placeholder="请输入邮箱地址" size="small"
							class="inp-w"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：">
						<el-switch v-model="form.status" active-color="#13ce66" inactive-color="#d3d3d3"
							:active-value="1" :inactive-value="0">
						</el-switch>
					</el-form-item>
					<el-form-item label="备注：">
						<el-input v-model="form.notes" type="textarea" autocomplete="off" placeholder="备注" size="small"
							class="inp-w"></el-input>
					</el-form-item>
					<!-- //取消，确定按钮 -->
					<div style="display: flex; justify-content: center; align-items: center">
						<el-button @click="showAdd = false" class="btn_w" size="small">取消</el-button>
						<el-button type="primary" @click="handleAddSave('form')" class="btn_w" size="small">确定
						</el-button>
					</div>
				</el-form>
			</el-dialog>
		</div>

		<!-- 密码重置 -->
		<div class="dailog">
			<el-dialog v-model="showResetPwd" :close-on-click-modal="false" title="密码重置" center :destroy-on-close="true"
				:width="'30%'" @close="ResetForm()">
				<el-form :model="form" :rules="rules" ref="form" size="small" label-position="right"
					:label-width="'100px'">
					<el-form-item label="密码：" prop="password">
						<el-input v-model="form.password" placeholder="请输入密码" type="password"></el-input>
					</el-form-item>
					<el-form-item label="确认密码：" prop="con_password">
						<el-input v-model="form.con_password" placeholder="确认密码" type="password"></el-input>
					</el-form-item>
					<div style="display: flex; justify-content: center; align-items: center">
						<el-button type="primary" size="small" class="btn_w" @click="handleResetPwd('form')">重置
						</el-button>
					</div>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [], //表格数据
				showAdd: false, //新增弹窗
				showResetPwd: false, //重置密码弹窗

				// 上传文件相关数据
				upLoadUrl: "/api/admin/upload/uploadImg",
				headers: {
					token: JSON.parse(localStorage.getItem("userInfo")).token,
					version: "1.0.0",
					api: "1",
				},
				UpdateImageData: {
					module: "admin",
					type: "logo",
				},
				// 页码
				pageSize: 10,
				pageIndex: 0,
				total: 0,

				title: "",
				//搜索数据
				formSearch: {
					name: "",
					account: "",
					status: "",
					phone: "",
				},
				//表单数据
				form: {
					plat_id: "", //平台ID
					name: "", //平台名称
					expire_time: "", //到期时间
					is_trial: "", //是否试用
					pname: "", //账号
					mobile: "", //手机
					email: "", //邮箱
					password: "", //密码
					con_password: "", //二次密码
					status: "", //是否启用
					notes: "", //备注
					avatar: "", //图片地址
				},
				// 表单规则
				rules: {
					name: [{
							required: true,
							message: "平台名称不能为空",
							trigger: "blur",
						},
						{
							max: 30,
							message: "不能超过30个字符",
						},
					],
					expire_time: [{
						required: true,
						message: "到期时间不能为空",
						trigger: "change",
					}, ],
					pname: [{
						required: true,
						message: "账号不能为空",
						trigger: "blur",
					}, ],
					password: [{
						required: true,
						message: "密码不能为空",
						trigger: "blur",
					}, ],
					con_password: [{
						required: true,
						message: "密码不能为空",
						trigger: "blur",
					}, ],
					mobile: [{
						required: true,
						message: "手机号不能为空",
						trigger: "blur",
					}, ],
					email: [{
						required: true,
						message: "邮箱不能为空",
						trigger: "blur",
					}, ],
					avatar: [{
						required: true,
						message: "LOGO不能为空",
						trigger: "blur",
					}, ],
				},
			};
		},
		created() {
			this.getPlatList();
			this.$options.data();
		},
		methods: {
			//日期禁用
			disabledDate(time) {
				return time.getTime() < Date.now() - 3600 * 1000 * 24;
			},
			// 重置form数据
			ResetForm() {
				this.form = this.$options.data().form;
			},
			//编辑获取行数据
			hanleeditPlat(e) {
				this.form = JSON.parse(JSON.stringify(e));
				this.title = "编辑账号";
				this.showAdd = true;
			},
			//重置密码
			handleResetPwd(form) {
				if (this.form.password !== this.form.con_password) {
					this.$message({
						message: "两次密码不一致!",
						type: "error",
					});
				} else {
					this.$refs[form].validate((valid) => {
						if (valid) {
							this.$axios
								.post("admin/plat/resetPwd", {
									plat_id: this.form.plat_id,
									pwd: this.form.password,
									confirm: this.form.con_password,
								})
								.then((res) => {
									this.$message({
										message: res.msg,
										type: "success",
									});
									this.showResetPwd = false;
									this.form = this.$options.data().form;
								});
						} else {
							return false;
						}
					});
				}
			},
			//修改状态
			hanleeditStatus(e) {
				this.$confirm("是否修改状态？", "提示", {
					confirmButtonText: "确定",
					canceButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$axios
						.post("admin/plat/editPlatStatus", {
							plat_id: e.id,
							status: e.status ? 0 : 1,
						})
						.then((res) => {
							this.$message({
								type: "success",
								message: res.msg,
							});
							this.UpdateData();
						});
				});
			},
			//删除
			hanleDetails(e) {
				let _this = this;
				this.$confirm("是否删除该账号？", "提示", {
					confirmButtonText: "确定",
					canceButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$axios
						.post("admin/plat/delPlat", {
							plat_id: e.id,
						})
						.then((res) => {
							this.$message({
								type: "success",
								message: res.msg,
							});
							_this.UpdateData();
						});
				});
			},
			// 添加、编辑保存
			handleAddSave(form) {
				console.log(this.form.expire_time);
				if (this.form.password !== this.form.con_password) {
					this.$message({
						message: "两次密码不一致!",
						type: "error",
					});
				} else {
					this.$refs[form].validate((valid) => {
						if (valid) {
							if (this.title == "添加账号") {
								this.$axios
									.post("admin/plat/addPlat", {
										name: this.form.name,
										expire_time: this.form.expire_time,
										logo: this.form.avatar,
										is_trial: this.form.is_trial,
										pname: this.form.pname,
										pwd: this.form.password,
										confirm: this.form.con_password,
										mobile: this.form.mobile,
										email: this.form.email,
										status: this.form.status,
										notes: this.form.notes,
									})
									.then((res) => {
										this.$message({
											message: res.msg,
											type: "success",
										});
										this.showAdd = false;
										this.form = this.$options.data().form;
										this.UpdateData();
									});
							} else if (this.title == "编辑账号") {
								this.$axios
									.post("admin/plat/editPlat", {
										plat_id: this.form.id,
										name: this.form.name,
										expire_time: this.form.expire_time,
										logo: this.form.avatar,
										is_trial: this.form.is_trial,
										pname: this.form.pname,
										mobile: this.form.mobile,
										email: this.form.email,
										status: this.form.status,
										notes: this.form.notes,
									})
									.then((res) => {
										this.$message({
											message: res.msg,
											type: "success",
										});
										this.showAdd = false;
										this.form = this.$options.data().form;
										this.UpdateData();
									});
							}
						} else {
							return false;
						}
					});
				}
			},
			// 文件限制上传大小
			beforeAvatarUpload(file) {
				const isJPG = file.type === "image/jpeg";
				if (!isJPG) {
					this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
				}
				return isJPG;
			},
			// 文件上传成功
			handleAvatarSuccess(e) {
				this.$message.success("上传成功!");
				this.form.avatar = e.data.url;
			},
			// 改变页数
			handleSizeChange(val) {
				this.pageSize = val;
				this.UpdateData();
			},
			// 改变页码
			handleCurrentChange(val) {
				this.pageIndex = (val - 1) * this.pageSize;
				this.UpdateData();
			},
			// 更新表格数据
			UpdateData() {
				this.$axios
					.get(
						"admin/plat/getPlatList?start=" +
						this.pageIndex +
						"&limit=" +
						this.pageSize +
						"&name=" +
						this.formSearch.name +
						"&pname=" +
						this.formSearch.account +
						"&mobile=" +
						this.formSearch.phone +
						"&status=" +
						this.formSearch.status
					)
					.then((res) => {
						this.tableData = res.data.list;
						this.total = res.data.count;
					});
			},
			// 初始化表格数据
			getPlatList() {
				this.$axios
					.get(
						"admin/plat/getPlatList?start=" +
						this.pageIndex +
						"&limit=" +
						this.pageSize
					)
					.then((res) => {
						this.tableData = res.data.list;
						this.total = res.data.count;
					});
			},
		},
	};
</script>

<style land="sass" scoped>
	.page_mar {
		margin: 10px;
	}

	.mod_mar {
		margin-bottom: 10px;
	}

	.input_mar {
		margin: 0 5px;
		width: 10%;
	}

	.inp-w {
		width: 100%;
	}

	.btn_w {
		width: 90px;
	}

	.el-upload {
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
		border-radius: 6px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 148px;
		height: 148px;
		cursor: pointer;
		line-height: 146px;
		vertical-align: top;
	}
</style>
